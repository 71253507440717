<template>
  <div class="bind_otp_download">
    <div class="bind_otp_download_icons mt_3em mb_2em">
      <img src="@/assets/images/login/ic_security_center_cell_phone.webp" />
      <img src="@/assets/images/login/ic_security_center_arrow.webp" />
      <img src="@/assets/images/login/ic_security_center_captcha.webp" />
    </div>
    <div class="bind_otp_download_info mb_3em">
      <div>首先，您需要在您的手机上安装一个谷歌验证器</div>
      <div>(Google Authenticator)</div>
      <div class="bind_otp_download_info_copy" v-clipboard:copy="downloadLink" v-clipboard:success="handleCopySuccess" v-clipboard:error="handleCopyError">
        [复制下载地址]
      </div>
    </div>
    <div class="bind_otp_download_link">
      <div>谷歌验证码 (安卓版)</div>
      <button class="btn small" @click="downloadApk">下载</button>
    </div>
    <button class="bind_otp_download_next btn oval mb_4rem" @click="goNextPage">下一步</button>
    <iframe ref="download" style="display:none;"></iframe>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  name: "BindOTPDownload",
  computed: {
    ...mapState("auth", ["googleOTPInfo"]),
    downloadLink() {
      return this.googleOTPInfo.androidGoogleAuthenticatorLink || "";
    },
  },
  methods: {
    ...mapActions("help", ["getHelp"]),
    handleCopySuccess(e) {
      console.log("copy success", e);
    },
    handleCopyError(e) {
      console.log(e);
    },
    downloadApk() {
      this.$refs.download.src = this.downloadLink;
    },
    goNextPage() {
      this.$router.push("/bindOTP/bind");
    },
  },
  mounted() {
    this.getHelp({
      code: "gooleteach",
      page: 1,
      limit: 20,
    });
  },
};
</script>

<style lang="scss" scoped>
.bind_otp_download {
  padding: 0 1em;
  position: relative;
  &_icons {
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 3em;
    }
    img:nth-child(2) {
      width: 2em;
      margin-left: 0.5rem;
      margin-right: 0.5rem;
    }
  }
  &_info {
    &_copy {
      margin-top: 0.8rem;
      color: var(--gray);
    }
  }
  &_link {
    border: 1px solid var(--light-orange);
    background: var(--very-light-orange);
    padding: 0.5rem 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &_next {
    position: fixed;
    width: calc(100% - 2rem);
    left: 50%;
    bottom: 0;
    transform: translate(-50%);
  }
}
</style>
